@mixin filter($property) {
  -webkit-filter: $property;
  -moz-filter: $property;
  -o-filter: $property;
  -ms-filter: $property;
  filter: $property;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -o-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -o-transition: $property;
  -ms-transition: $property;
  transition: $property;
}

@mixin background-size($property) {
  -webkit-background-size: $property;
  -moz-background-size: $property;
  -o-background-size: $property;
  background-size: $property;
}

@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
