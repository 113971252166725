@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');
@import 'bootstrap/scss/bootstrap.scss';
@import '../../common/scss/variables.scss';

body {
  font-family: 'Quicksand', Helvetica, sans-serif;
  color: $color-white;
  text-align: center;
  background-color: $color-background;
}

img {
  image-rendering: -webkit-optimize-contrast; // Improve resized images in chrome
}

a {
  color: $color-white;

  &:hover {
    color: $color-white;
  }
}
